<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" centered @cancel="cancel" @ok="create">
      <div slot="title">新增入库</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item prop="material" label="产品">
            <material-select v-model="form.material" :defaultItem="form.material_item" />
          </a-form-model-item>
          <a-form-model-item prop="location" label="库位">
            <location-search-select v-model="form.location" :warehouse="currentWarehouse" :defaultItem="form.location_item" />
          </a-form-model-item>
          <a-form-model-item prop="stock_in_quantity" label="入库数量">
            <a-input-number v-model="form.stock_in_quantity" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="batch_number" label="批次编号">
            <a-input v-model="form.batch_number" />
          </a-form-model-item>
          <!-- <a-form-model-item prop="production_date" label="生产日期">
            <a-date-picker v-model="form.production_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item> -->
          <a-form-model-item prop="stock_in_date" label="入库日期">
            <a-date-picker v-model="form.stock_in_date" valueFormat="YYYY-MM-DD" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { stockInRecordCreate } from "@/api/stockIn";
import { rules } from "./rules";
import moment from "moment";

export default {
  components: {
    LocationSearchSelect: () => import("@/components/LocationSearchSelect/"),
    MaterialSelect: () => import("@/components/MaterialSelect/"),
  },
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  data() {
    return {
      rules,
      loading: false,
      form: {},
    };
  },
  methods: {
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          stockInRecordCreate(this.form)
            .then((data) => {
              this.$message.success("新增成功");
              this.$parent.items = this.$functions.insertItem(this.$parent.items, data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.form = { ...this.item };
      }
    },
  },
};
</script>

<style scoped></style>
